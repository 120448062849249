<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">用户车辆变更记录</span>
        </div>
        <el-card class="el-main">
            <le-pagview ref="userCarChangePage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.userCarChangeList">
                <el-table ref="userCarChangeList" :data="tableData" :highlight-current-row="true" style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column prop="id" label="车辆类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.type | initDic(typeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="品牌" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.brand || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="电瓶规格" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.batteryVoltage | initDic(batteryVoltageDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="车牌" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.carNumber || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="车辆照片" min-width="120">
                        <template slot-scope="{ row }">
                            <el-image
                                style="width: 80px; height: 80px; border-radius: 8rpx; margin-right: 10px"
                                :preview-src-list='[row.imagePath]'
                                :src="row.imagePath"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="充电器照片" min-width="120">
                        <template slot-scope="{ row }">
                            <el-image
                                style="width: 80px; height: 80px; border-radius: 8rpx; margin-right: 10px"
                                :preview-src-list='[row.imagePaths]'
                                :src="row.imagePaths"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="修改时间" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.changeTime || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="修改人" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.changeMobile || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="修改类型" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.changeType | initDic(changeTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="备注" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.remark || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="审批人" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.reviewMobile || '-' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
import util from '../../utils/util.js'
export default {
    data() {
        return {
            util: util,
            tableData: [],
            pageParam: {
                url: this.$Config.apiUrl.getTimelineList,
                method: 'post',
                params: {
                    userId: this.$route.query.userId,
                },
                freshCtrl: 1,
            },
            typeDic: [],//车辆类型字典
            batteryVoltageDic: [],//车辆电瓶规格字典
            changeTypeDic: [],
        }
    },
    computed: {
        ...mapState({
            company: state => state.company.company
        })
    },
    created () {
        this.$getDic('userBikeType','select').then(res=>{ this.typeDic = res; })
        this.$getDic('userBikeBattery','select').then(res=>{ this.batteryVoltageDic = res; })
        this.$getDic('userBikeChangeType','select').then(res=>{ this.changeTypeDic = res; })
    },
    mounted() { },
    methods: {
        //获取用户列表
        setTableData(data) {
            this.tableData = data.map(item=>{
                let bikeInfo
                try {
                    bikeInfo = JSON.parse(item.bikeInfo)
                } catch (error) {
                    
                }
                return {
                    ...bikeInfo,
                    ...item,
                }
            })
        },
        handlerRest() {
            this.pageParam.params = {
                userId: this.$route.query.userId,
            }
            this.handlerSearch()
        },

        handlerSearch() {
            this.$refs['userCarChangePage'].pageNum = 1
            this.pageParam.freshCtrl++
        },
    },
}
</script>

<style scoped lang="scss">
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>
